import { GlobalStyles, ThemeProvider } from "@greco/components";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { HistoryRouter } from "redux-first-history/rr6";

import { ToastContainer } from "react-toastify";
import { getUserPhotoSmall } from "./api/GraphService";
import { UserDataLoader } from "./api/UserDataLoader";
import "./App.scss";
import { AppHeader, UserHeader } from "./components/AppHeader/AppHeader";
import { sideBarWidth } from "./components/config/ui";
import { NoAccess } from "./components/NoAccess";
import { CrmSidebar } from "./components/Sidebar/CrmSidebar";
import { default as CrmSideBarContextProvider } from "./components/Sidebar/CrmSideBarContext";

import { Tooltip } from "./components/Tooltip";
import { headerConfig, IHeaderConfig } from "./config/headerConfig";
import { routes } from "./config/routes";
import { setIsLoggedIn } from "./store/auth";
import { loadAzureMaps } from "./store/azureMaps";
import { useSelector } from "./store/hooks";
import { AppDispatch, history } from "./store/store";
import { loadTaxonomyActions } from "./store/taxonomy/taxonomy";
import { setIsLoadingUserRole, setUserRoleAndEmail } from "./store/userRole";

import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { darkStyledTheme, styledTheme } from "./theme/index";

interface IAppState {
  headerConfig: IHeaderConfig;
  panelIsOpen: boolean;
  user: any;
  error: string;
  loading: boolean;
  search: string;
  appInsights: ApplicationInsights;
}

export const AppGos = () => {
  const [state, setState] = useState<IAppState>({
    headerConfig: headerConfig,
    panelIsOpen: false,
    user: {},
    error: "",
    loading: false,
    search: "",
    appInsights: null,
  });
  const [user, setUser] = useState<UserHeader | null>(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const hasRights = useSelector((s) => s.auth.hasRights);

  // let query = useQuery();
  // useEffect(() => {
  //   const darkMode = query.get("darkMode");
  //   if (darkMode) {
  //     localStorage.setItem("darkMode", darkMode);
  //     // setState({
  //     //   ...state,
  //     //   headerConfig: {
  //     //     ...state.headerConfig,
  //     //     darkMode: darkMode,
  //     //   },
  //     // });
  //   }
  // }, [query]);

  // on mount
  useEffect(() => {
    const fn = async () => {
      const usp = new URLSearchParams(window.location.search);

      dispatch(setIsLoggedIn({ isLoggedIn: true }));
    };
    fn();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      dispatch(loadAzureMaps());
      dispatch(loadTaxonomyActions.trigger());
      dispatch(setIsLoadingUserRole(true));
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      setIsUserLoading(true);
      const userData = await UserDataLoader.getUserData();
      const imgUrl = await getUserPhotoSmall();
      setUser({ ...userData, photoSmall: imgUrl, photoMedium: imgUrl });
      dispatch(
        setUserRoleAndEmail({
          userRole: "internal",
          email: userData.userPrincipalName,
        })
      );
      setIsUserLoading(false);
    })();
  }, []);

  return (
    <HistoryRouter history={history}>
      <StyledThemeProvider
        theme={
          (Boolean(state.headerConfig.darkMode)
            ? darkStyledTheme
            : styledTheme) as any
        }
      >
        <ThemeProvider isDarkMode={Boolean(state.headerConfig.darkMode)}>
          <CrmSideBarContextProvider>
            {hasRights && user && (
              <>
                <AppHeader
                  key={Boolean(state.headerConfig.darkMode).toString()}
                  user={user}
                  darkMode={(isDarkMode) => {
                    localStorage.setItem("darkMode", String(isDarkMode));
                    setState({
                      ...state,
                      headerConfig: {
                        ...state.headerConfig,
                        darkMode: isDarkMode,
                      },
                    });
                  }}
                />
                <CrmSidebar />
                <div
                  className={`app-wrapper`}
                  style={{
                    marginLeft: `${sideBarWidth}px`,
                  }}
                >
                  <Routes>
                    {Object.keys(routes).map((key) => {
                      const route = routes[key];
                      return (
                        <Route
                          key={key}
                          path={route.path}
                          element={<route.component />}
                        />
                      );
                    })}
                    <Route
                      path="*"
                      element={<Navigate to="/locations" replace />}
                    />
                  </Routes>

                  <ToastContainer />
                  <GlobalStyles />
                  <Tooltip />
                </div>
              </>
            )}
            {!hasRights && <NoAccess />}
          </CrmSideBarContextProvider>
        </ThemeProvider>
      </StyledThemeProvider>
    </HistoryRouter>
  );
};
